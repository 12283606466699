<template>
    <div>
        <!-- <div class="nfs-locale-switch-container">
            <LanguageSwitcher />
        </div> -->
        <!-- Sticky header -->
        <div
            class="nfs-base-layer"
            dir="ltr"
        >
            <!-- headless ui transitioned sidebar pops out from leftside, for 📱 screen when hamburger menu is clicked -->
            <AnimatedModal
                :show="sidebarOpen"
                @close="()=> sidebarOpen = false"
            >
                <NfsSidebar
                    :navigations="sidebarNavigationProps"
                    @action="()=> sidebarOpen = false"
                />
            </AnimatedModal>
            <div class="nfs-sticky-header border-b border-neutral-200">
                <NfsHeader
                    :show-icon="!sidebarOpen"
                    @action="()=>sidebarOpen = true"
                />
            </div>
            <!-- Static sidebar for desktop -->
            <div
                dir="ltr"
                class="nfs-static-sidebar"
            >
                <NfsSidebar
                    :navigations="sidebarNavigationProps"
                    @action="()=> sidebarOpen = false"
                />
            </div>
            <!-- <div class="nfs-breadcrumb">
                <BreadCrumb />
            </div> -->
            <main class="nfs-page-layer">
                <!-- app Page -->
                <slot />
            </main>
        </div>
    </div>
</template>

<script setup lang="ts">
import { jwtDecode } from 'jwt-decode';
import { NavItemObject } from '../types/navItemObject';
import { navigations } from '../types/constants';
import { User } from '~/types/user';

const sidebarOpen = ref(false);
const isAuthenticated = ref(false);
const { layer0, layer1, layer2 } = useRuntimeConfig().public.appLayout.zIndex;
const sidebarNavigationProps = ref<NavItemObject[]>();
watchEffect(() => {
    sidebarNavigationProps.value = navigationProps(navigations);
});

const user = useUser().value;

const token = localStorage.getItem('authToken');
if (token) {
    const decodedToken : User = jwtDecode(token);
    user.name = decodedToken.name;
    user.email = decodedToken.email;
    user.sub = decodedToken.sub;
    user.role_id = decodedToken.role_id;
    user.permissions = decodedToken.permissions;
    useUser().value = decodedToken;
    isAuthenticated.value = true;
    useRegisterSubscriber();
} else
    isAuthenticated.value = false;
</script>

<style scoped>
.nfs-base-layer {
    @apply max-w-[1366px] min-h-screen flex bg-neutral-100 flex-col mx-auto z-[v-bind(layer0)]; /*1366px => maximum 3 issue-thumbnails(320px) in 1 row + static sidebar (width:256px) */
}
.nfs-locale-switch-container {
    @apply max-w-[1366px] mx-auto flex justify-end rtl:justify-start sticky bg-white top-0 right-0 z-[v-bind(layer1)];
}
.nfs-static-sidebar {
    @apply hidden sm:fixed sm:top-0 sm:bottom-6 sm:z-[3] sm:flex sm:w-[256px] sm:flex-col;
}
.nfs-sticky-header {
    @apply sticky top-0 z-[v-bind(layer2)] flex shrink-0 items-center gap-x-6;
}
.nfs-breadcrumb {
    @apply sticky top-[115px] sm:top-16 z-[v-bind(layer1)]  items-center gap-x-6  sm:pl-[256px] max-w-full bg-neutral-100;
}
.nfs-page-layer {
    @apply p-4 sm:pl-[256px] max-w-full z-[v-bind(layer0)]; /* 256px sidebar width + 16px left-space design req = 272px*/
}

</style>

<template>
    <div>
        <MenuDropDown
            :options="menuOptions"
            :show-default-button="false"
            menu-btn-type="transparent"
            @menu-option-clicked="handleLogout"
        >
            <img
                class="size-10"
                :src="props.picture || '/images/user_avatar.png'"
                :alt="props?.name"
            >
            <div class="text-left">
                <span class="block truncate text-sm/5 font-normal text-neutral-900">{{ props.name }}</span>
                <span class="block truncate text-xs/5 font-light text-neutral-900">{{ props.email }}</span>
            </div>
        </MenuDropDown>
    </div>
</template>

<script setup lang="ts">
import { useUnRegisterSubscriber } from '../composables/pushNotifications';
const props = defineProps({
    name: {
        type: String,
        default: ''
    },
    email: {
        type: String,
        default: ''
    },
    picture: {
        type: String,
        default: ''
    }
});

const menuOptions = [
    {
        title: 'Logout',
        link: '',
        callback: true
    }
];

const handleLogout = async(option) => {
    if (option.title === 'Logout') {
        await useUnRegisterSubscriber();
        localStorage.removeItem('authToken');
        window.location.href = '/';
    }
};
</script>

<style scoped>
.user-details {
  @apply z-[1] p-4 shadow bg-neutral-100 w-64
}
</style>

<template>
    <div
        dir="ltr"
        class="ml-auto flex min-w-full items-center bg-neutral-100 px-6 sm:hidden sm:py-0 lg:py-4"
    >
        <div class="flex shrink-0 items-end sm:mx-0 sm:border-none sm:px-0">
            <NuxtLinkLocale
                to="/"
                class="flex items-center gap-2"
            >
                <img
                    class="h-5 w-auto lg:hidden"
                    src="/images/logo.png"
                    alt="N-Issues"
               ><span class="text-sm font-medium lg:hidden">N-Issues</span>
            </NuxtLinkLocale>
        </div>
        <div class="ml-auto flex items-center gap-6">
            <div :class="['py-6 pl-6 transition-opacity sm:hidden', props.showIcon?'opacity-100' : 'opacity-0']">
                <NfsIcon
                    icon="hamberger-menu"
                    :cursor="true"
                    size="small"
                    @action="openSidebarMenu"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    showIcon: {
        type: Boolean,
        default: true
    }
});
const emit = defineEmits<(e: 'action') => void>();

const openSidebarMenu = () => {
    emit('action');
};

</script>
